.country-modal {
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    transform: translateX(100%);
    width: clamp(20rem, calc(100dvw - var(--header-height)), 32rem);
    height: 100dvh;
    background-color: var(--color--white);
    transition-duration: var(--transition-slow);
    transition-delay: 0s;
    box-shadow: var(--shadow-small);
    z-index: 21;

    &.-active {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        transition-delay: 0.35s;

        & + .country-modal__overlay {
            visibility: visible;
            opacity: 0.1;
            transition-delay: 0.4s;
        }
    }

    &.-no-data {
        .country__month {
            display: none;
        }
    }
}

.country-modal__overlay {
    background: linear-gradient(90deg, rgba(0,0,0, 0.5) 0%, rgba(0,0,0, 1) 100%);
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s;
    transition-duration: var(--transition-slow);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    z-index: 20;
}

html.-modal {
    header {
        @media screen and (max-width: 1023px) {
            .month {
                bottom: -8rem;
            }
        }
    }

    .top-countries {
        opacity: 0;
        visibility: hidden;
        transition-duration: var(--transition-slow);

        @media screen and (max-width: 1023px) {
            right: -100%;
        }

        @media screen and (min-width: 1024px) {
            left: -20rem;
        }
    }
}
